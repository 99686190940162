<template>
    <div class="book-detail">
      <p class="title">环境管理体系内审员培训教程</p>
      <div>
        <p class="ptittle">书本介绍</p>
        <span
          >本书为实施环境管理体系的企业内审员讲述了审核的基本知识和方法，以及分享了大量的审核案例。</span
        >
      </div>
      <br />
      <div>
        <p class="ptittle">内容介绍</p>
        <span
          >为了在国内大力宣传ISO14001标准，帮助企业建立环境管理体系，中国标准出版社与我司共同策划了一套《ISO14001理论与实践丛书》，本书是其中之一。</span
        >
        <br />
        <br />
        <span>另外，尽管内部审核和第三方审核的目的有所不同，但第三方审核的方式、方法和技巧却非常值得我们参考。从一定意义上讲，内部审核的难度比第三方审核更高，一个出色的内审员所需要的能力并不比外审员低。</span>
        <br />
        <span>
            在ISO14001环境管理体系中，内部审核是一个必不可少的环节。具体实施内部环境管理体系审核时由于绝大多数建立环境管理体系的组织已通过了ISO9001认证，自然会充分利用内部质量管理体系审核的经验和手法。毫无疑问，ISO9001与ISO14001有着很强的兼容性，内部环境管理体系审核应最大限度地借鉴ISO9001的成果。但是，这并不意味着简单的照搬，因为二者毕竟存在着许多不同，特别是没有将二者一体化的组织，更应考虑这些不同点。例如，在对象、成本转移、覆盖范围和对持续改进的要求方面，ISO9001和ISO14001均有不同，在制定审核计划、任命审核员和编制审核清单时都有着各自的特点。有效的内部环境管理体系审核有赖于两个基本点，一是审核员正确理解ISO14001标准的要求和企业的自身特点，二是审核员对审核原则、方法和技巧的掌握。
        </span>
      </div>
    </div>
  </template>
    
    <script>
  export default {};
  </script>
    
    <style lang="less" scoped>
  @import "../../../assets/style/BookStyle.css";
  </style>